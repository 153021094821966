/* Footer */
.footer__inner {
  padding: 64px 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  .row .col {
    flex-grow: 1;
  }

  @media (max-width: $wide) {
    .row .col:first-child {
      width: 100%;
      margin-bottom: 64px;
    }
  }

  @media (max-width: $desktop) {
    .row .col {
      margin-bottom: 48px;

      &:first-child {
        margin-bottom: 48px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

   @media (max-width: $mobile) {
     padding: 48px 0;
   }
}

/* Widgets */
.widget-title {
  margin-bottom: 24px;
  font-size: 32px;

  @media (max-width: $wide) {
    font-size: 28px;
  }
}

.widget-footer__desc {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--text-alt-color);
}

/* Widget Info */
.widget-info {
  width: 360px;

  .logo-footer__link {
    display: inline-flex;
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: -1px;
  }

  .logo-footer__image,
  .logo-footer__image__dark {
    max-height: 50px;
  }

  .logo-footer__image__dark {
    display: none;
  }

  @media (max-width: $wide) {
    width: 100%;
  }
}

.dark-mode {
  .logo-footer__image {
    display: none;
  }

  .logo-footer__image__dark {
    display: block;
  }
}

/* Social */
.social {
  .social__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .social__item:first-child > .social__link {
      padding-left: 0;
    }
  }

  .social__item {
    margin-bottom: 0;
    margin-right: 16px;
  
    &:last-child {
      margin-right: 0;
    }
  }

  .social__link {
    display: flex;
    align-items: center;
    padding: 4px;
    font-size: 22px;
    color: var(--heading-font-color);
    will-change: transform;
    transition: $global-transition;

    &:hover {
      transform: scale(1.15);
    }
  }
}

/* Widget Recent Posts */
.widget-recent-posts__title {
  font-size: 28px;
}

.recent-posts {
  position: relative;
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    margin-bottom: 32px;
  }
}

.recent-posts__image {
  position: relative;
  display: block;
  transform: translate(0);
  min-width: 180px;
  aspect-ratio: 4/3;
  margin-right: 20px;
  border-radius: 22px;
  overflow: hidden;
  background: var(--background-alt-color);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @supports not (aspect-ratio: 4/3) {
    height: 0;
    padding-bottom: 75%;
  }

  .video-icon {
    width: 48px;
    height: 48px;

    svg {
      width: 36px;
    }
  }

  @media (max-width: $mobile) {
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    border-radius: 16px;

    .video-icon {
      width: 68px;
      height: 68px;
  
      svg {
        width: 44px;
      }
    }
  }
}

.recent-posts__content {
  .article__categories {
    .article__category {
      padding: 3px 12px
    }
  }

  .recent-posts__title {
    font-size: 16px;
    line-height: 1.4;
    margin: 8px 0;

    a {
      text-decoration: underline;
      text-decoration-color: transparent;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
      color: var(--heading-font-color);

      &:hover {
        text-decoration-color: var(--heading-font-color);
      }
    }
  }

  @media (max-width: $mobile) {
    .article__categories {
      .article__category {
        padding: 6px 16px;
      }
    }

    .recent-posts__title {
      font-size: 23px;
      a {
        text-decoration-thickness: 1.5px;
        text-underline-offset: 3px;
      }
    }
  }
}

/* Widget Subscribe */
.widget-subscribe {
  width: 380px;
  margin-left: auto;

  .subscribe-form {
    display: flex;
  }

  .subscribe-email {
    width: 100%;
    height: 54px;
    flex: 1 0 220px;
    margin-right: 8px;
    padding: 20px;
    font-size: 14px;
    line-height: 1.3;
    border: 1px solid var(--border-color);
    border-radius: 60px;
    outline: 0;
    color: var(--heading-font-color);
    background-color: var(--background-alt-color);

    &::placeholder {
      color: var(--heading-font-color);
    }
  }

  @media (max-width: $wide) {
    width: 100%;
  }

  @media (max-width: $mobile) {
    .subscribe-form {
      flex-direction: column;
    }

    .subscribe-email {
      flex: 1 0 auto;
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

/* Footer Info */
.footer__info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 32px 0;

  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer__info__right {
  display: flex;
  align-items: center;
}

.copyright {
  margin-right: auto;
  font-size: 14px;
  color: var(--heading-font-color);

  a {
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    color: var(--heading-font-color);

    &:hover {
      text-decoration-color: var(--heading-font-color);
    }
  }
}

/* Toggle Theme */
.toggle-theme {
  margin-right: 24px;
  line-height: 1;
  color: var(--heading-font-color);
  cursor: pointer;

  &:hover {
    .toggle-text {
      text-decoration-color: var(--heading-font-color);
    }
  }

  .toggle-text {
    margin-left: 8px;
    font-size: 15px;
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }

  @media (max-width: $tablet) {
    margin-right: 16px;

    .toggle-text {
      font-size: 14px;
    }
  }
}

.toggle-sun,
.toggle-moon {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.toggle-sun {
  display: none;
}

.dark-mode {
  .toggle-sun {
    display: flex;
  }

  .toggle-moon {
    display: none;
  }
}

/*!------------------------------------------------------------------
[MAIN STYLESHEET]
-------------------------------------------------------------------*/

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
:: Template name: Danto
:: Danto is a fast, modern, optimized, and mobile-friendly Jekyll theme. It is fully compatible with GitHub Pages, supports videos from YouTube and Vimeo, and also has a light and dark mode. This theme has been designed with a focus on readability to provide your visitors with a quality user experience and is perfect for a personal blog, news, magazine, and all kinds of publishing websites.
:: Template Author: Artem Sheludko
:: Version: 1.0
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>
:: 1-Tools and Libs
    1.1-Normalize
    1.2-Reset
    1.3-Shared
    1.4-Syntax Highlighting
    1.5-Grid
    1.6-Animate
:: 2-Base Styles
    2.1-Base
:: 3-Modules
    3.1-Header
    3.2-Search
    3.3-Pagination
    3.4-Footer
    3.5-Gallery
    3.6-Hero
    3.7-Article
    3.8-Contact
    3.9-Sections
    3.10-Section categories
    3.11-Sidebar
    3.12-Scroll Button Top
    3.13-Buttons
:: 4-Layouts
    4.1-Post Page
    4.2-Authors
    4.3-categories Page
<<<<<<<<<<<<<<<<<<<<<<<<<<<< */


@import '0-settings/variables';
@import '0-settings/color-scheme';
@import '0-settings/mixins';
@import '0-settings/helpers';


/* =======================
:: 1-Tools and Libs
======================= */
/* >>>>>>>>>>>>>> :: 1.1-Normalize <<<<<<<<<<<<<<< */
@import '1-tools/normalize';
/* >>>>>>>>>>>>>> :: 1.2-Reset <<<<<<<<<<<<<<< */
@import '1-tools/reset';
/* >>>>>>>>>>>>>> :: 1.3-Shared <<<<<<<<<<<<<<< */
@import '1-tools/shared';
/* >>>>>>>>>>>>>> :: 1.4-Syntax Highlighting <<<<<<<<<<<<<<< */
@import '1-tools/syntax-highlighting';
/* >>>>>>>>>>>>>> :: 1.5-Grid <<<<<<<<<<<<<<< */
@import '1-tools/grid';
/* >>>>>>>>>>>>>> :: 1.6-Animate <<<<<<<<<<<<<<< */
@import '1-tools/animate';


/* =======================
:: 2-Base Styles
======================= */
/* >>>>>>>>>>>>>> :: 2.1-Base <<<<<<<<<<<<<<< */
@import '2-base/base';


/* =======================
:: 3-Modules
======================= */
/* >>>>>>>>>>>>>> :: 3.1-Header <<<<<<<<<<<<<<< */
@import '3-modules/header';
/* >>>>>>>>>>>>>> :: 3.2-Search <<<<<<<<<<<<<<< */
@import '3-modules/search';
/* >>>>>>>>>>>>>> :: 3.3-Pagination <<<<<<<<<<<<<<< */
@import '3-modules/pagination';
/* >>>>>>>>>>>>>> :: 3.4-Footer <<<<<<<<<<<<<<< */
@import '3-modules/footer';
/* >>>>>>>>>>>>>> :: 3.5-Gallery <<<<<<<<<<<<<<< */
@import '3-modules/gallery';
/* >>>>>>>>>>>>>> :: 3.6-Hero <<<<<<<<<<<<<<< */
@import '3-modules/hero';
/* >>>>>>>>>>>>>> :: 3.7-Article <<<<<<<<<<<<<<< */
@import '3-modules/article';
/* >>>>>>>>>>>>>> :: 3.8-Contact <<<<<<<<<<<<<<< */
@import '3-modules/contact';
/* >>>>>>>>>>>>>> :: 3.9-Sections <<<<<<<<<<<<<<< */
@import '3-modules/sections';
/* >>>>>>>>>>>>>> :: 3.10-Section categories <<<<<<<<<<<<<<< */
@import '3-modules/section-categories';
/* >>>>>>>>>>>>>> :: 3.11-Sidebar <<<<<<<<<<<<<<< */
@import '3-modules/sidebar';
/* >>>>>>>>>>>>>> :: 3.12-Scroll Button Top <<<<<<<<<<<<<<< */
@import '3-modules/scroll-button-top';
/* >>>>>>>>>>>>>> :: 3.13-Buttons <<<<<<<<<<<<<<< */
@import '3-modules/buttons';
/* >>>>>>>>>>>>>> :: 3.14-Banners <<<<<<<<<<<<<<< */
@import '3-modules/banners';


/* =======================
:: 4-Layouts
======================= */
/* >>>>>>>>>>>>>> :: 4.1-Post <<<<<<<<<<<<<<< */
@import '4-layouts/post';
/* >>>>>>>>>>>>>> :: 4.2-Authors <<<<<<<<<<<<<<< */
@import '4-layouts/authors';
/* >>>>>>>>>>>>>> :: 4.3-categories Page <<<<<<<<<<<<<<< */
@import '4-layouts/categories-page';

/* categories */
.categories__inner {

  &.is-hidden {
    display: none;
  }

  .category__info {
    margin: 48px 0;
  }
  
  .category__title {
    position: relative;
    margin-bottom: 0;
    font-size: 48px;
    text-transform: capitalize;
  }
  
  .category__counter {
    position: absolute;
    top: -10px;
    padding: 3px 8px;
    min-width: 26px;
    min-height: 26px;
    font-family: $base-font-family;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    border-radius: 40px;
    color: var(--heading-font-color);
    background-color: var(--background-alt-color);
  }

  @media (max-width: $tablet) {
    .category__info {
      margin: 16px 0 32px;
    }

    .category__title {
      font-size: 35px;
    }

    .category__counter {
      padding: 2px 6px;
      min-width: 20px;
      min-height: 20px;
      font-size: 12px;
      line-height: 1.3;
    }
  }

  @media (max-width: $mobile) {
    .category__title {
      font-size: 32px;
    }
  }
}

/* Section categories */
.section-categories {
  @media (max-width: $desktop) {
    .row .col:last-child {
      flex-grow: 1;
    }
  }
}

.category-image {
  margin-bottom: 32px;
  position: relative;
  transform: translate(0);
  display: block;
  height: 0;
  padding-bottom: 62%;
  border-radius: 8px;
  overflow: hidden;
  background: var(--background-alt-color);
  transition: transform .2s;

  &:hover {
    transform: translateY(-3px);
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }

  .category-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    max-width: 90%;
    display: inline-block;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    color: var(--heading-font-color);
    background: var(--background-color);
    pointer-events: none;
  }
}

/* Buttons */
.button {
  display: inline-flex;
  align-items: center;
  padding: 8px 18px;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
  text-decoration: none;
  border-radius: 60px;
  border: none;
  outline: none;
  color: var(--button-color);
  background: var(--button-background-color);
  transition: $global-transition;
  will-change: transform;
  cursor: pointer;

  &:hover {
    color: var(--button-color);
    background: var(--button-background-hover);
  }

  &--big {
    padding: 14px 24px;
  }

  i {
    font-size: 14px;
    margin-left: 6px;
  }
}
/* Color Scheme */

:root {
  --primary-color: #f8ce5f;
  --white: #fff;
  --light-gray: #f0f0f0;
  --lighten-blue: #eaecef;
  --light-blue: #e4e6eb;
  --blue-gray: #697177;
  --gray: #9e9e9e;
  --dark: #111;
  --dark-blue: #1e2943;

  --background-color: var(--white);
  --background-alt-color: var(--lighten-blue);
  --background-alt-color-2: var(--light-blue);
  
  --text-color: var(--dark);
  --text-alt-color: var(--blue-gray);
  
  --heading-font-color: var(--dark);

  --link-color: var(--dark);
  --link-color-hover: var(--dark);

  --button-color: #320101;
  --button-background-color: var(--primary-color);
  --button-background-hover: #edc352;

  --border-color: var(--light-blue);
  --border-alt-color: var(--white);

  --th-color: var(--light-gray);
  --tr-color: var(--light-gray);

  --syntax-highlighting-background: var(--light-gray);
}

:root[dark] {
  --primary-color: #fbdab1;
  --white: #fff;
  --light-gray: #f5f5f5;
  --gray: #9a9a9a;
  --dark: #2a2b2d;
  --dark-blue: #181818;

  --background-color: var(--dark-blue);
  --background-alt-color: var(--dark);
  --background-alt-color-2: #262729;
  
  --text-color: var(--gray);
  --text-alt-color: var(--gray);
  
  --heading-font-color: var(--light-gray);

  --link-color: var(--light-gray);
  --link-color-hover: var(--light-gray);

  --button-color: var(--dark);
  --button-background-color: var(--primary-color);
  --button-background-hover:#d3b795;

  --border-color: #333;
  --border-alt-color: #333;

  --th-color: var(--dark);
  --tr-color: var(--dark);

  --syntax-highlighting-background: var(--dark);
}
/* Authors */

// Section Authors
.section-authors__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .section-authors__title {
    margin-bottom: 0;
    font-size: 48px;
    line-height: 1;
  }

  .section-authors__box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 48px;

    .section-authors__image {
      position: relative;
      transform: translate(0);
      width: 128px;
      height: 128px;
      border: 4px solid var(--border-alt-color);
      border-radius: 50%;
      overflow: hidden;
      margin-right: -48px;
      background-color: var(--background-alt-color);
      transition: transform .3s ease;

      &:nth-child(1) {
        z-index: 5;
      }

      &:nth-child(2) {
        z-index: 4;
      }

      &:nth-child(3) {
        z-index: 3;
      }

      &:nth-child(4) {
        z-index: 2;
      }

      &:nth-child(5) {
        z-index: 1;
      }

      &:hover {
        transform: translateY(-2px);
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
  }

  .section-authors__link {
    display: block;
    margin-top: 12px;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    text-align: right;
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;

    &:hover {
      text-decoration-color: var(--heading-font-color);
    }
  }

  @media (max-width: $desktop) {
    flex-direction: column;
    align-items: flex-start;

    .section-authors__title {
      margin-bottom: 20px;
      font-size: 35px;
    }

    .section-authors__link {
      text-align: left;
    }
  }

  @media (max-width: $tablet) {

    .section-authors__box {
      margin-right: 50px;

      .section-authors__image {
        width: 108px;
        height: 108px;
        margin-right: -50px;
      }
    }

    .section-authors__link {
      font-size: 12px;
    }
  }

  @media (max-width: $mobile) {
    border-radius: 16px;

    .section-authors__title {
      font-size: 28px;
    }

    .section-authors__box {
      margin-right: 36px;

      .section-authors__image {
        width: 80px;
        height: 80px;
        margin-right: -36px;
        border-width: 3px;
      }
    }
  }
}

.dark-mode {
  .section-authors__box {
    .section-authors__image {
      border-color: var(--border-alt-color);
    }
  }
}

// Authors Page
.authors {

  .authors__head {
    margin: 48px 0;
  }

  .authors__title {
    font-size: 48px;
  }

  .authors__info {
    padding: 32px;
    margin-bottom: 32px;
    border-radius: 22px;
    background-color: var(--background-alt-color);
  }

  .authors__image {
    position: relative;
    transform: translate(0);
    display: block;
    width: 108px;
    height: 108px;
    margin-bottom: 12px;
    border-radius: 50%;
    border: 4px solid var(--border-alt-color);
    overflow: hidden;
    background-color: var(--background-color);
    transition: transform .3s ease;

    &:hover {
      transform: translateY(-3px);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  .authors__meta {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  .authors__name {
    display: inline-block;
    font-size: 20px;
    margin-right: 8px;
    margin-bottom: 0;
    line-height: 1.4;

    .authors__link {
      text-decoration: underline;
      text-decoration-color: transparent;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;

      &:hover {
        text-decoration-color: var(--link-color-hover);
      }
    }
  }

  .authors__posts__count {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--heading-font-color);
  }

  .authors__description {
    margin-bottom: 0;
    font-size: 16px;
    color: var(--text-alt-color);
  }

  @media (max-width: $tablet) {
    .authors__head {
      margin: 16px 0 32px;
    }

    .authors__title {
      font-size: 35px;
    }
  }

  @media (max-width: $mobile) {
    .authors__title {
      font-size: 32px;
    }

    .authors__info {
      border-radius: 16px;
    }

    .authors__image {
      margin-bottom: 8px;
    }
  }
}

// Author Page
.author {

  .author__content {
    margin: 48px 0 32px;
    padding-bottom: 40px;
    text-align: center;
    border-bottom: 1px solid var(--border-color);

    p {
      max-width: 560px;
      margin: 0 auto;
      font-size: 18px;
      color: var(--text-alt-color);
    }
  }

  .author__image {
    position: relative;
    transform: translate(0);
    width: 128px;
    height: 128px;
    margin: 0 auto 20px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--background-alt-color);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  .author__name {
    margin-bottom: 20px;
    line-height: 1;
  }

  .author__info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 12px;

    .author__social__item {
      padding: 6px 16px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 15px;
      border-radius: 60px;
      color: var(--link-color);
      background-color: var(--background-alt-color);
      transition: $global-transition;

      &:hover {
        background-color: var(--background-alt-color-2);
      }

      &:last-child {
        margin-right: 0;
      }

      i {
        margin-right: 4px;
      }
    }
  }

  .author__posts__count {
    margin-bottom: 20px;
    font-size: 18px;
  }

  @media (max-width: $mobile) {

    .author__content {
      margin: 16px 0 32px;
      padding-bottom: 32px;

      p {
        font-size: 16px;
      }
    }

    .author__name {
      font-size: 32px;
    }

    .author__info {
      .author__social__item {
        margin-right: 6px;
        margin-bottom: 6px;
        font-size: 14px;
      }
    }
  }
}
/* Post + Page */
.post__head {
  margin: 48px 0;

  @media (max-width: $tablet) {
    margin: 16px 0 32px;
  }
}

.post__meta {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
}

.post__categories {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  .post__category {
    padding: 6px 16px;
    margin: 3px 6px 3px 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 60px;
    color: var(--heading-font-color);
    transition: $global-transition;
    background-color: var(--background-alt-color);

    &:hover {
      background-color: var(--background-alt-color-2);
    }
  
    &:last-child {
      margin-right: 0;
    }
  }
}

.post__title,
.page__title {
  margin-bottom: 24px;
  font-size: 60px;

  @media (max-width: $desktop) {
    margin-bottom: 20px;
    font-size: 48px;
  }

  @media (max-width: $tablet) {
    font-size: 35px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 16px;
    font-size: 32px;
  }
}

.post__description,
.page__description {
  margin-bottom: 0;
  font-size: 18px;
  color: var(--text-alt-color);

  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

.post-image,
.page-image {
  position: relative;
  transform: translate(0);
  aspect-ratio: 4/3;
  margin-bottom: 32px;
  border-radius: 22px;
  overflow: hidden;
  background: var(--background-alt-color);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }

  @supports not (aspect-ratio: 4/3) {
    padding-bottom: 75%;
  }

  @media (max-width: $mobile) {
    border-radius: 16px;
  }
}

.post-video,
.page-video {
  margin-bottom: 32px;
  border-radius: 22px;
  overflow: hidden;
  transform: translate(0);
  background-color: var(--background-alt-color);

  .post-video__wrap,
  .page-video__wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $mobile) {
    border-radius: 16px;
  }
}

.post,
.page {
  color: var(--text-color);

  a {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }

  img,
  .lightense-wrap,
  .gallery {
    &+em {
      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          text-decoration-color: var(--link-color);
        }
      }
    }
  }

  img, .js-reframe {
    border-radius: 16px;
    overflow: hidden;

    &.lightense-open {
      border-radius: 0;
    }
  }

  .button {
    border: none;
    text-decoration: none;
  }
}

// Page
.page {
  max-width: 780px;
  margin: 48px auto 64px;

  .page__description {
    margin-bottom: 32px;
  }

  img[src$='#wide'] {
    display: block;
    width: 100vw;
    max-width: none;
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    pointer-events: none;
    user-select: none;
  }

  @media (max-width: $mobile) {
    margin: 48px auto;
  }
}

// Post Share
.post__share {
  margin-top: 48px;

  .share__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -3px 0;
  }

  .share__item {
    margin: 3px;
    text-align: center;
  }

  .share__link {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 15px 20px;
    line-height: 1;
    font-weight: 500;
    border-radius: 60px;
    text-decoration: none;
    color: var(--heading-font-color);
    background-color: var(--background-alt-color);
    transition: $global-transition;

    &:hover {
      background-color: var(--background-alt-color-2);
    }

    .share-name {
      &::before {
        content: "";
        position: relative;
        top: 0;
        display: inline-block;
        margin: 0 8px;
        width: 1px;
        height: 10px;
        opacity: 0.2;
        background-color: var(--heading-font-color);
      }
    }
  }

  @media (max-width: $tablet) {
    margin-top: 0;

    .share__link {
      font-size: 13px;
    }
  }

  @media (max-width: $mobile) {
    .share__link {
      font-size: 16px;
      padding: 10px 25px;

      .share-name {
        display: none;
      }
    }
  }
}

/* Navigation */
.post__navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  padding: 48px 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  .post__prev,
  .post__next {
    display: flex;
    align-items: center;
    width: 48%;
    border: none;
  }

  .prev__image,
  .next__image {
    position: relative;
    transform: translate(0);
    display: block;
    min-width: 260px;
    aspect-ratio: 4/3;
    border-radius: 22px;
    overflow: hidden;
    background-color: var(--background-alt-color);
    transition: transform .3s ease;

    @supports not (aspect-ratio: 4/3) {
      padding-top: 31%;
    }

    &:hover {
      transform: translateY(-3px);

      .video-icon {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-icon {
      width: 60px;
      height: 60px;
      will-change: transform;
    }
  }

  .prev__box {
    margin-left: 20px;
  }

  .next__box {
    margin-right: 20px
  }

  .post__nav {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    color: var(--text-alt-color);
  }

  .post__nav__title {
    margin-bottom: 0;
    font-size: 24px;

    a {
      text-decoration: underline;
      text-decoration-color: transparent;
      text-decoration-thickness: 1.5px;
      text-underline-offset: 3px;

      &:hover {
        text-decoration-color: var(--link-color-hover);
      }
    }
  }

  .post__next {
    text-align: right;
    margin-left: auto;
  }

  @media (max-width: $wide) {
    .prev__image,
    .next__image {
      min-width: 240px;

      @supports not (aspect-ratio: 4/3) {
        padding-top: 40%;
      }
    }

    .post__nav__title {
      font-size: 22px;
    }
  }

  @media (max-width: $desktop) {
    padding: 0 0 48px;
    border-top: none;
    border-bottom: 1px solid var(--border-color);

    .post__prev,
    .post__next {
      align-items: normal;
      flex-direction: column;
    }

    .prev__image,
    .next__image {
      margin-bottom: 12px;

      @supports not (aspect-ratio: 4/3) {
        padding-top: 75%;
      }
    }

    .prev__box,
    .next__box {
      order: 1;
      margin: 0;
    }

  }

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    margin-top: 48px;

    .post__prev,
    .post__next {
      width: 100%;
    }

    .prev__image,
    .next__image {
      border-radius: 16px;

      .video-icon {
        width: 68px;
        height: 68px;
        will-change: transform;
      }
    }

    .post__prev {
      margin-bottom: 32px;
    }

    .post__next {
      margin-left: 0;
    }
  }
}

/* Related posts */
.related-posts {
  display: none;
  margin: 64px auto 0;

  &.is-related {
    display: block;
  }

  @media (max-width: $wide) {
    .article__meta,
    .article__info {
      display: none;
    }

    .article__title {
      font-size: 18px;
    }
  }

  @media (max-width: $mobile) {
     margin: 48px auto 0;
  }
}

/* Disqus */
.show-comments {
  text-align: center;
}

.disqus-button {
  margin: 64px 0;

  @media (max-width: $mobile) {
    margin: 48px 0;
  }
}

.post__comments {
  max-width: 780px;
  margin: 0 auto;

  &.is-open {
    margin: 64px auto;
  }

  @media (max-width: $mobile) {
     &.is-open {
       margin: 48px auto;
     }
  }
}

/* 404 */
.error {
  text-align: center;

  .error__title {
    margin-bottom: 24px;
    font-size: 140px;
    line-height: 1;
  }

  .error__text {
    color: var(--text-alt-color);
  }
  
  @media (max-width: $mobile) {
    .error__title {
      font-size: 100px;
    }
  }
}

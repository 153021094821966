/* banners */
.banners {
  margin-bottom: 64px;

  .banners__inner {
    border-bottom: 1px solid var(--border-color);
  }

  .banner__image {
    padding-bottom: 62%;
    background: var(--background-color);

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
